import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig'
import reportWebVitals from './reportWebVitals';
import ThemeProvider from './Contexts/ThemeProvider'; 
import ChatProvider from './Contexts/ChatProvider';
import AnalyticsProvider from './Contexts/AnalyticsProvider';

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <MsalProvider instance={msalInstance}>
    <ThemeProvider> 
    <ChatProvider> 
    <AnalyticsProvider> 
      <App />
    </AnalyticsProvider>
     </ChatProvider>
    </ThemeProvider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
