export const generateImage = async (username, prompt,size) => {
  const apiUrl = process.env.REACT_APP_DALLE_API_URL;
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, prompt ,size}),
    });
    const data = await response.json();

    if (!response.ok) {
      // Return the full error response from the API
      return data;
    }

    return data;
  } catch (error) {
    console.error('Error generating image:', error);
    return null;
  }
};


export const regenerateImage = async (id, prompt,size) => {
    const apiUrl = process.env.REACT_APP_REGENERATE_API_URL;
    
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, prompt ,size}),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error generating image:', error);
      return null;
    }
  };
  
export const retrieveImages = async (username) => {
  const apiUrl = `${process.env.REACT_APP_RETRIEVE_API_URL}?username=${username}`;

  try {
    const response = await fetch(apiUrl);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    
    const data = await response.json();
    return data; 
  } catch (error) {
    console.error("Error retrieving images:", error);
    return [];
  }
};



export const deleteChatHistory = async (username, itemId, allDeleted) => {
  const baseUrl = process.env.REACT_APP_DB_DELETE_API_URL;
  let url = baseUrl;

  if (allDeleted) {
      url += `?uname=${encodeURIComponent(username)}&option=true`;
  } else if (itemId) {
      url += `?uname=${encodeURIComponent(username)}&item_id=${itemId}`;
  } else {
      console.error('Invalid parameters: either itemId or allDeleted must be provided.');
      return;
  }
  try {
      const response = await fetch(url, {
          method: 'DELETE',
      });

      if (response.ok) {
          const data = await response.json();
          console.log('Delete successful:', data);
      } else {
          console.error('Error deleting item:', response.statusText);
      }
  } catch (error) {
      console.error('Error during delete operation:', error);
  }
};

