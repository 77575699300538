import React, { createContext, useContext, useState } from 'react';

const AnalyticsContext = createContext();

export const useAnalytics = () => useContext(AnalyticsContext);

const AnalyticsProvider = ({ children }) => {

  const [loading, setLoading] = useState({ tab1: false, tab2: false });

  // Tab 1 states
  const [aggregatedData, setAggregatedData] = useState([]);
  const [promptData, setPromptData] = useState([]);
  const [maxUserCount, setMaxUserCount] = useState(0);
  const [avgUserCount, setAvgUserCount] = useState(0);
  const [maxUserCountPrompt, setMaxUserCountPrompt] = useState(0);
  const [avgUserCountPrompt, setAvgUserCountPrompt] = useState(0);

  // Tab 2 states
  const [timeFrame, setTimeFrame] = useState('daily');
  const [tableData, setTableData] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

 


  return (
    <AnalyticsContext.Provider
      value={{
          aggregatedData, maxUserCount,
        avgUserCount, promptData, timeFrame, setTimeFrame,loading, setLoading, tableData,maxUserCountPrompt, setMaxUserCountPrompt,avgUserCountPrompt, setAvgUserCountPrompt,
        fromDate, setFromDate, toDate, setToDate,setAggregatedData,setMaxUserCount,setAvgUserCount,setPromptData,setTableData
        
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;