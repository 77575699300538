import React from "react";
import { Dialog, DialogContent, IconButton, Typography, Box } from "@mui/material";
import { IoCloseOutline } from "react-icons/io5";
import { CiWarning } from "react-icons/ci";
import { useThemes } from "../Contexts/ThemeProvider";
import { motion } from "framer-motion";

const WarnModal = ({ open, handleClose,warnHader,warnDetails }) => {
  const { theme } = useThemes();
  const backgroundColor = theme === "dark" ? "#363636" : "#FFFFFF";
  const textColor = theme === "dark" ? "#FFFFFF" : "#000000";

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      sx={{
        "& .MuiPaper-root": {
          background: "none",
          overflow: "hidden",
          boxShadow: "none",
        },
      }}
    >
      {/* Animated Box Container */}
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        style={{
          backgroundColor,
          borderRadius: "12px",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "5px",
          textAlign: "center",
          color: textColor,
        }}
      >
        {/* Close Button */}
        <Box sx={{ alignSelf: "flex-end" , padding:'0px',}}>
          <IconButton
            onClick={handleClose}
            sx={{
              padding:'0px',
              color: textColor,
              background: "rgba(255,255,255,0.1)",
              "&:hover": { background: "rgba(255,255,255,0.2)" },
            }}
          >
            <IoCloseOutline size={24} />
          </IconButton>
        </Box>

        {/* Warning Icon */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding:'0px',
            width: { xs: 48, sm: 80 },
            height: { xs: 48, sm: 80 },
            backgroundColor: "#FFCDD2",
            borderRadius: "50%",
          }}
        >
          <CiWarning size={40} color="#d32f2f" />
        </Box>

        {/* Title */}
        <Typography
          variant="h6"
          padding='0px'
          sx={{
            fontWeight: "bold",
            fontSize: "1.25rem",
          }}
        >
          {warnHader}
        </Typography>

        {/* Message Content */}
        <DialogContent
          sx={{
            padding:'0px',
            maxWidth: "300px",
            textAlign: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "1rem",
            }}
          >
            {warnDetails}
          </Typography>
        </DialogContent>
      </motion.div>
    </Dialog>
  );
};

export default WarnModal;
