import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Routes, Route,Navigate } from 'react-router-dom';
import { saveLoginDetails } from '../APIServices/LoginAPI';
import { useThemes } from '../Contexts/ThemeProvider';
import { useChat } from '../Contexts/ChatProvider';
import { useMsal } from '@azure/msal-react';
import { Dots } from 'react-activity';
import 'react-activity/dist/library.css';

// Lazy load components
const Dashboard = lazy(() => import('../screens/Dashboard'));
const GetStartedScreen = lazy(() => import('../screens/GetStarted'));
const AnalyticsDashboard = lazy(() => import('../screens/AnalyticsDashboard'));

// ProtectedRoute HOC
const ProtectedRoute = ({ isAuthenticated, children }) => {
  return isAuthenticated ? children : <Navigate to="/" replace />;
};


const AppRoutes = () => {
  const [loading, setLoading] = useState(false);
  const { instance, accounts } = useMsal();
  const { setProfilePicture, setUser } = useThemes();
  const { setUserChat } = useChat();
  const isAuthenticated = accounts.length > 0;

  useEffect(() => {
    if (isAuthenticated) {
      setUser(accounts[0].name);
      setUserChat(accounts[0].name);
    }
  }, [isAuthenticated,accounts, setUser, setUserChat]);

  const handleLogin = async () => {
    setLoading(true);
    try {
      await instance.loginPopup(); 
      const account = instance.getAllAccounts();
      if (account.length > 0) {
        const request = {
          account: account[0],
          scopes: ['User.Read'],
        };
        const response = await instance.acquireTokenSilent(request);
        const accessToken = response.accessToken;
        const graphResponse = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (graphResponse.ok) {
          const blob = await graphResponse.blob();
          const url = URL.createObjectURL(blob);
          setProfilePicture(url);
        } else if (graphResponse.status === 404) {
          console.warn('Profile picture not found');
          setProfilePicture(null); 
        } else {
          console.error('Failed to fetch profile picture', graphResponse.status, graphResponse.statusText);
        }
        const useremail = typeof account[0].username === 'string' ? account[0].username : '';
        const username = typeof account[0].name === 'string' ? account[0].name : '';
        if (!useremail || !username) {
          console.error('Missing useremail or username');
          return;
      }  
        await saveLoginDetails(useremail,username);
      }
    } catch (error) {
      console.error('Login failed', error);
    } finally {
      setLoading(false);
    }
  };

 
  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <Dots size={30} color="#0565FF" />
      </div>
    );
  }

  return (
    <Suspense fallback={<div style={styles.loadingContainer}><Dots size={30} color="#0565FF" /></div>}>
      <Routes>
        <Route path="/" element={isAuthenticated ? <Dashboard /> : <GetStartedScreen handleLogin={handleLogin} />} />
        <Route path="/analytics" 
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
              <AnalyticsDashboard />
            </ProtectedRoute>} 
            />
      </Routes>
    </Suspense>
  );
};

const styles = {
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
};

export default AppRoutes;
