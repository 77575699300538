import React from 'react';
import { Snackbar } from '@mui/material';
import { useThemes } from '../../Contexts/ThemeProvider'; // Adjust the path to your context

const CustomSnackbar = () => {
  const { snackbarOpen, handleCloseSnackbar, snackbarMessage, theme } = useThemes();

  // Adjust styles based on theme
  const backgroundColor = theme === 'dark' ? '#F9F9F9' : '#282828';
  const textColor = theme === 'dark' ? '#000' : '#fff';

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      message={snackbarMessage}
      ContentProps={{
        sx: {
          bgcolor: backgroundColor,
          color: textColor,
          border: `1px solid ${textColor}`,
        },
      }}
    />
  );
};

export default CustomSnackbar;
