export const saveLoginDetails = async (useremail, username) => {
    try {
        const url = `${process.env.REACT_APP_DB_USERLOGINDETAILSAVE_API_URL}?useremail=${encodeURIComponent(useremail)}&username=${encodeURIComponent(username)}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ useremail, username }) 
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const textResponse = await response.text();
        try {
            const responseData = JSON.parse(textResponse);
            return responseData;
        } catch (jsonError) {
            throw new Error('Response is not valid JSON');
        }
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
};
  
  export const getLoginDetails = async () => {  
    try {
        const response = await fetch(process.env.REACT_APP_DB_USERLOGINDETAILRETRIEVE_API_URL);
        if (!response.ok) {
            throw new Error('Failed to retrieve login details');
        }
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error('Error retrieving login details:', error);
    }
};
