import React from 'react';
import { HashRouter } from 'react-router-dom';
import AppRoutes from './router/AppRoutes'; 
import { ToastContainer } from 'react-toastify';
import CustomSnackbar from './components/ReuseComponets/CustomSnackbar';
import WarnModal from './components/WarnModal';
import { useThemes } from './Contexts/ThemeProvider';
import ErrorBoundary from './ErrorBoundary';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { warnModalHeader ,isWarnModalOpen, setWarnModalOpen , warnModalMessage} = useThemes();

  return (
    <HashRouter>
      <CustomSnackbar/>
     <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          pauseOnHover
          draggable
          theme="colored"
        />

     {isWarnModalOpen && (
       <WarnModal
       open={isWarnModalOpen}
       handleClose={() => setWarnModalOpen(false)}
       warnHader={warnModalHeader}
       warnDetails={warnModalMessage}
      />  
      )}

    <ErrorBoundary>
      <AppRoutes />
    </ErrorBoundary>
    </HashRouter>
  );
}

export default App;
