const saveThemeSetting = async ({ theme, username }) => { 
    try {
        const url = `${process.env.REACT_APP_THEME_SETTINGS_SAVE}?theme=${encodeURIComponent(theme)}&username=${encodeURIComponent(username)}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ theme, username }),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const textResponse = await response.text(); 
        try {
            const responseData = JSON.parse(textResponse); 
            return responseData;
        } catch (jsonError) {
            console.warn('Response is not valid JSON, returning raw text');
            return textResponse;
        }
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        throw error; 
    }
};




const getThemeSettingByUname = async (_loggedInUserName) => {
    try {
        const url = `${process.env.REACT_APP_THEME_SETTINGS_RETRIEVE}?username=${encodeURIComponent(_loggedInUserName)}`;
        const response = await fetch(url);
        if (!response.ok) {
            console.log(response, "fetch response");
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonData = await response.json();

        if (Array.isArray(jsonData) && jsonData.length > 0) {
            const lastThemeEntry = jsonData[jsonData.length - 1];
            return lastThemeEntry.theme; 
        } else {
            console.warn('No theme data found in the response');
            return null;
        }
    } catch (error) {
        console.error('Error retrieving theme settings:', error);
        throw error; 
    }
};



export {saveThemeSetting, getThemeSettingByUname};