import React from 'react';
import styles from '../styles/ErrorBoundaryUI.module.css';
import Logo from '../assets/svg/LogoLighttheme.svg';
import RightImage from '../assets/svg/GetstartedRightImage.svg';

const ErrorBoundaryUI = () => {

  const onRetry = () => {
    window.location.reload(); // Refresh the window
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {/* Left Section */}
        <div className={styles.SectionOne}>
          <img src={Logo} alt="AFS Innovation Logo" className={styles.logo} />
        </div>

        {/* Right Section */}
        <div className={styles.SectionTwo}>
          <img src={RightImage} alt="Illustration" className={styles.rightImage} />
          <h1>Oh no , Something went wrong!</h1>
          <button className={styles.getStartedButton} onClick={onRetry}>
            Refresh
          </button>
        </div>
      </div>
      <p className={styles.copyright}>© ArentFox Schiff LLP </p>
    </div>
  );
};

export default ErrorBoundaryUI;
